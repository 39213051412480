import React from 'react';
import * as KatalMetrics from "@amzn/katal-metrics";
import { KatSpinner } from '@amzn/katal-react';
import { ErrorBoundComponent, ErrorBoundState } from "src/components/error/ErrorBoundComponent";
import { AxiomMetricsDriver, MetricStatus } from "src/metrics/AxiomMetricsDriver";
import { CerebrumService } from 'src/service/CerebrumCoralService';
import SideBar from './sidebar/SideBar';
import RadarCard from './radar_card/RadarCard';
import RecommendationCard from './recommendation_card/RecommendationCard';
import UserMocks from "../../../__mocks__/src/service/UserMocks";
import { AxiomIsItDown } from '../common/AxiomIsItDown';
import { UserContext } from 'src/context/UserContext';
import { LayoutContext } from "src/components/layout/LayoutContext";
import { UserManager } from "src/user/UserIdentityManager";
import { AxiomRight } from "src/config/AxiomCSRights";


// TODO Move to Tenant Config
export const BSC_METRICS_LIST = ['acht', 'paa', 'pma', 'ro', 'trans'];

// TEMP disabling pa and oa
export const VALUE_SHOWN_METRICS = ['none']; // ['pa', 'oa'];

export const NEW_HIRE_METRIC_NAME = 'nh';

enum BscVersionId {
    v3 = 'v3',
    v6 = 'v6',
    v7 = 'v3'
}

export const DEFAULT_VERSION_IDS = {
    BSC: BscVersionId.v3,
    RECS: BscVersionId.v3
};

const IS_IT_DOWN_COMP_ID = 'bsc';

export function filterNonBscMetrics(metricName: string) {
    return BSC_METRICS_LIST.indexOf(metricName) >= 0;
}

const URL_PARAM_KEYS = {
    SELECTED_AGENT: 'selectedAgent',
    SELECTED_METRIC: 'selectedMetric',
};

const EMPTY_BSC_DATA = {
    metrics: [],
    score: 0,
    datasetDate: "",
    versionId: ""
};

const BSC_INITIAL_EMPTY_STATE: BSCState = {
    userBalancedScorecardData: {
        agentBalancedScorecards: [{
            agent: "",
            balancedScorecard: EMPTY_BSC_DATA
        }],
        supervisor: "",
        teamBalancedScorecard: EMPTY_BSC_DATA
    },
    selectedEntity: {
        type: 'agent',
        userId: "",
        bscMetricData: EMPTY_BSC_DATA
    },
    selectedFilters: [],
    viewUserDetails: {
        login: "",
        supervisor: false,
        reports: [],
        accessRights: UserMocks.ACCESS_RIGHTS
    },
    hasError: false,
    errorTitle: 'wfm_axiom_v2_bsc_error_title'
};

export interface BSCProps {
    viewUserLogin: string;
}

export interface BSCState extends ErrorBoundState {
    userBalancedScorecardData: Cerebrum.UserBalancedScorecardData;
    selectedEntity: Axiom.SelectedUser;
    selectedFilters: Axiom.SelectionFilter[];
    viewUserDetails: WFM.GetUserDetails;
}

export default class BSC extends ErrorBoundComponent<BSCProps, BSCState> {
    static contextType = UserContext;
    context!: React.ContextType<typeof UserContext>;

    private componentName: string = "BSC";
    private timeOnPageMetric = new KatalMetrics.Metric.TimerStopwatch('pageTimer').withMonitor();

    constructor(props: BSCProps) {
        super(props);
        this.state = BSC_INITIAL_EMPTY_STATE;
        this.errorMetricHandler = this.loadComponentErrorMetricHandler.bind(this);
        this.selectedEntityHandler = this.selectedEntityHandler.bind(this);
        this.selectedMetricHandler = this.selectedMetricHandler.bind(this);
        this.userBalancedScorecardDataAdapter = this.userBalancedScorecardDataAdapter.bind(this);
        this.viewUserUpdate = this.viewUserUpdate.bind(this);
        this.updateSelectedFilters = this.updateSelectedFilters.bind(this);
        this.mainContent = this.mainContent.bind(this);
        this.publishPageLoad = this.publishPageLoad.bind(this);
    }

    public async componentDidMount() { // async/await usage here is discussed in CR-25789512
        await this.viewUserUpdate();
        this.publishPageLoad();
        this.setFiltersFromUrl();
    }

    private publishPageLoad() {
        const additionalMetrics = [
            {
                name: "viewUserLogin",
                value: this.props.viewUserLogin
            }
        ];
        AxiomMetricsDriver.publishPageLoad(this.componentName, window.location.href, additionalMetrics);
    }

    private loadComponentErrorMetricHandler() {
        AxiomMetricsDriver.publishComponentLoadError(this.componentName, MetricStatus.Failure);
    }

    async componentDidUpdate(prevProps: Readonly<BSCProps>) {
        if (this.props == prevProps) {
            return;
        }
        if (this.props.viewUserLogin !== prevProps.viewUserLogin) {
            this.setState(BSC_INITIAL_EMPTY_STATE); // Clears the page while the user waits for the new data
            await this.viewUserUpdate();
            this.publishPageLoad();
            this.setFiltersFromUrl();
        }
    }

    componentWillUnmount() {
        if (this.timeOnPageMetric) {
            AxiomMetricsDriver.publishMonitor(this.timeOnPageMetric, this.componentName + ".timer");
        }
        super.componentWillUnmount();
    }

    /**
     * Current bsc version for the user based on assigned weblab treatment
     */
    private get bscVersionId() {
        return DEFAULT_VERSION_IDS.BSC;
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="bsc-sidebar">
                        <SideBar selectEntityHandler={this.selectedEntityHandler} {...this.state} />
                    </div>
                    <LayoutContext.Consumer>
                        {layout => {
                            const bscMainClass = layout.isSidebarVisible && layout.isSidebarPinned ?
                                "bsc-main-pinned" :
                                "bsc-main-overlay";
                            return <div className={bscMainClass}>
                                <AxiomIsItDown componentId={IS_IT_DOWN_COMP_ID} />
                                <this.mainContent />
                            </div>;
                        }}
                    </LayoutContext.Consumer>
                </div>
            </div>
        );
    }

    private mainContent() {
        let content = super.renderError();
        if (!content) {
            const viewUser = this.state.viewUserDetails?.login || "";
            const userBalancedScorecardData = this.state.userBalancedScorecardData;
            if (viewUser != "" && userBalancedScorecardData.supervisor != "") { // validates user and data to display otherwise child components will crash the page
                content = (
                    <>
                        <RadarCard selectedMetricHandler={this.selectedMetricHandler} {...this.state} />
                        <RecommendationCard {...this.state} />
                    </>
                );
            } else {
                content = <KatSpinner size="large" />; // Waiting for balancedScorecardResponse
            }
        }
        return content;
    }

    async viewUserUpdate(): Promise<void> {
        let userBalancedScorecardDataRaw: Cerebrum.GetAgentBalancedScorecard | Cerebrum.ListSupervisorBalancedScorecards | null;
        let selectedEntity: Axiom.SelectedUser;

        const viewUserDetails = await CerebrumService.getUserDetails(this.props.viewUserLogin)
            .catch(error => {
                this.handleError(error);
            });
        const loggedInUserIsSupervisor = UserManager.getUserIdentity().accessRights[AxiomRight.SUPERVISOR_VIEW];

        document.title = `${SITE_NAME} - ${this.componentName} - ${this.props.viewUserLogin}`;

        if (viewUserDetails) {
            /**
             * Request the data from the correct cerebrum endpoint based on the user type
             * Set the initial selectedEntity based on the user type
             */
            if (viewUserDetails.supervisor) { // view user is a sup, so we need to prep the sup view
                userBalancedScorecardDataRaw = await CerebrumService.listSupervisorBalancedScorecards(
                    this.props.viewUserLogin,
                    { versionId: this.bscVersionId }
                )
                    .catch(error => {
                        this.handleError(error, loggedInUserIsSupervisor);
                        return null;
                    });

                // The initial selected entity for sup view is the sup himself
                selectedEntity = {
                    type: 'supervisor',
                    userId: this.props.viewUserLogin,
                    bscMetricData: userBalancedScorecardDataRaw?.teamBalancedScorecard || EMPTY_BSC_DATA
                };
            } else { // if the view user is not a sup they must be an agent
                userBalancedScorecardDataRaw = await CerebrumService.getAgentBalancedScorecard(
                    this.props.viewUserLogin,
                    { versionId: this.bscVersionId }
                )
                    .catch(error => {
                        this.handleError(error, loggedInUserIsSupervisor);
                        return null;
                    });

                // The initial selected entity for agent view is the agent himself
                selectedEntity = {
                    type: 'agent',
                    userId: this.props.viewUserLogin,
                    bscMetricData: userBalancedScorecardDataRaw?.agentBalancedScorecard || EMPTY_BSC_DATA
                };
            }

            if (userBalancedScorecardDataRaw) {
                /**
                 * Adapt the data from cerebrum into a single format and set the new state
                 */
                const userBalancedScorecardData = this.userBalancedScorecardDataAdapter(userBalancedScorecardDataRaw);
                if (userBalancedScorecardData) {
                    this.setState({
                        userBalancedScorecardData: userBalancedScorecardData,
                        selectedEntity: selectedEntity,
                        viewUserDetails: viewUserDetails
                    });
                }
                AxiomMetricsDriver.publishComponentLoadError(this.componentName, MetricStatus.Success);
            }
        }
    }

    /**
     * Adapts either agent or sup data from Cerebrum into a single UserBalancedScorecardData interface
     *
     * @param data Cerebrum.GetAgentBalancedScorecard | Cerebrum.ListSupervisorBalancedScorecards
     */
    userBalancedScorecardDataAdapter(data: Cerebrum.GetAgentBalancedScorecard | Cerebrum.ListSupervisorBalancedScorecards): Cerebrum.UserBalancedScorecardData {
        let userBalancedScorecardData: Cerebrum.UserBalancedScorecardData = BSC_INITIAL_EMPTY_STATE.userBalancedScorecardData;
        if ("agent" in data) {
            userBalancedScorecardData = {
                supervisor: data.supervisor,
                teamBalancedScorecard: data.teamBalancedScorecard,
                agentBalancedScorecards: [{
                    agent: data.agent,
                    balancedScorecard: data.agentBalancedScorecard
                }]
            };
        } else if ("agentBalancedScorecards" in data) {
            userBalancedScorecardData = {
                supervisor: this.props.viewUserLogin,
                teamBalancedScorecard: data.teamBalancedScorecard,
                agentBalancedScorecards: data.agentBalancedScorecards
            };
        } else {
            this.handleError("Invalid or outdated data recovered from Cerebrum. If the issue persists please contact the administrator.");
            return userBalancedScorecardData;
        }

        // TEMP: Fixes for using real data from Cerebrum
        // Round all the scores
        this.eachRecursiveRoundAnyNumber(userBalancedScorecardData);
        // Sort the agents by combo score
        userBalancedScorecardData.agentBalancedScorecards.sort((a, b) => a.balancedScorecard.score - b.balancedScorecard.score);
        // Remove the combined score from each bsc data
        userBalancedScorecardData.teamBalancedScorecard.metrics =
            userBalancedScorecardData.teamBalancedScorecard.metrics.filter(metric => this.filterNonBscMetrics(metric.name));
        userBalancedScorecardData.agentBalancedScorecards = userBalancedScorecardData.agentBalancedScorecards.map(bsc => {
            bsc.balancedScorecard.metrics = bsc.balancedScorecard.metrics.filter(metric => this.filterNonBscMetrics(metric.name));
            return bsc;
        });

        return userBalancedScorecardData;
    }

    eachRecursiveRoundAnyNumber(obj: any) {
        for (var k in obj) {
            if (typeof obj[k] == "object" && obj[k] !== null) {
                this.eachRecursiveRoundAnyNumber(obj[k]);
            } else {
                // Round all scores unless they are in the VALUE_SHOWN Metrics
                if (!VALUE_SHOWN_METRICS.includes(obj['name']) && (k === 'score')) {
                    // If the property can be parsed as a number then it is rounded
                    obj[k] = isNaN(obj[k]) ? obj[k] : Math.round(obj[k]).toString();
                }
            };
        };
    }

    // Removes metrics that should not be included based on the current BSC version, adds hidden metrics
    private filterNonBscMetrics(metricName: string) {
        const metricsList = [...BSC_METRICS_LIST, NEW_HIRE_METRIC_NAME];
        return metricsList.indexOf(metricName) >= 0;
    }

    /**
     * Allows deep links to preselect a specific agent filter recommendations
     *
     * @note only applies when component mounts or props change, adding these alone does not change props. Subsequently
     *  removes the param to avoid confusion from users because they do not update.
     *
     * @usage add `?selectedAgent=xxx&selectedMetric=xxx` to the end of the url to apply selections
     */
    private setFiltersFromUrl() {
        const urlSearch = new URLSearchParams(window.location.href.split("?")[1]);
        const urlSelectedAgent = urlSearch.get(URL_PARAM_KEYS.SELECTED_AGENT);
        const urlSelectedMetric = urlSearch.get(URL_PARAM_KEYS.SELECTED_METRIC);
        if (urlSelectedAgent) {
            const entityBsc = this.state.userBalancedScorecardData.agentBalancedScorecards.filter(
                bsc => bsc.agent == urlSelectedAgent
            )[0].balancedScorecard;
            this.selectedEntityHandler({
                type: "agent",
                userId: urlSelectedAgent,
                bscMetricData: entityBsc
            });
        }
        if (urlSelectedMetric) {
            this.selectedMetricHandler(urlSelectedMetric.toLowerCase());
        }
        window.location.href = window.location.href.split("?")[0];
    }

    /*
    * Event Handlers
    * */
    selectedEntityHandler(selectedEntity: Axiom.SelectedUser) {
        const nAgentFilter: Axiom.SelectionFilter = { field: "agentId", value: selectedEntity.userId };

        if (selectedEntity.userId !== this.state.selectedEntity.userId) {
            // if the user is not already selected then select them
            this.setState({ selectedEntity: selectedEntity });
        } else {
            // if the user is already selected then reset to the supervisor
            this.setState({
                selectedEntity: {
                    type: 'supervisor',
                    userId: this.state.userBalancedScorecardData.supervisor,
                    bscMetricData: this.state.userBalancedScorecardData.teamBalancedScorecard
                }
            });
        };
        if (selectedEntity.userId !== this.state.userBalancedScorecardData.supervisor && this.state.viewUserDetails.supervisor) {
            this.updateSelectedFilters(nAgentFilter);
        } else {
            // if the user is an agent or if the supervisor was clicked then just remove the agent filter
            let currentFiltersMinusAgent = this.state.selectedFilters.filter(filter => filter.field !== "agentId");
            this.setState({ selectedFilters: currentFiltersMinusAgent });
        };
        AxiomMetricsDriver.publishButtonClick('SideBar', 'selectUser', [{
            name: 'selectedUser',
            value: selectedEntity.userId
        }]);
    }

    selectedMetricHandler(metric: string) {
        const nMetricFilter: Axiom.SelectionFilter = { field: "metric", value: metric };
        this.updateSelectedFilters(nMetricFilter);
    }

    /**
     * Generalized helper to update selection filters;
     * This will replace any filter in the same field
     * if the new filter passed is identical to the existing filter then it is removed
     *
     * This facilitates the "toggle" like actions
     *
     * @param nFilter
     */
    updateSelectedFilters(nFilter: Axiom.SelectionFilter) {
        const currentSelectedFilters = [...this.state.selectedFilters];
        let indexOfFilter = currentSelectedFilters.findIndex((filter) => {
            return filter.field === nFilter.field && filter.value === nFilter.value;
        });
        let nFilters = currentSelectedFilters.filter(filter => filter.field !== nFilter.field);

        // if it was included, remove it
        if (indexOfFilter != -1) {
            this.setState({ selectedFilters: nFilters });
        }
        // otherwise add it
        else {
            nFilters.push(nFilter);
            this.setState({ selectedFilters: nFilters });
        }
    }
}