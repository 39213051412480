import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@katal/metricsDriverSushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import { UserManager } from "src/user/UserIdentityManager";
import KatalMetricObject from "@amzn/katal-metrics/lib/metricObject/KatalMetricObject";
import KatalMetricTimerStopwatch from "@amzn/katal-metrics/lib/metricObject/KatalMetricTimerStopwatch";

/**
 * Can replace the KatalMetricsDriverConsoleLogJson.publish without throwing errors
 * Silences the logging to reduce clutter in dev console when metrics are not needed.
 */
const silentMetricsConsolePublisher = (metricObject: KatalMetrics.Metric.Object, context: KatalMetrics.Context) => { };

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

/*Notice that Failure is 1, because errors are more meaningful when making graphics of these metrics*/
export enum MetricStatus {
    Success = 0,
    Failure = 1,
}

export enum InitializationStatus {
    Failure = 0,
    Success = 1,
}

class AxiomMetrics {
    private metricsDriver?: KatalMetrics.MetricsDriver;
    private metricsPublisher?: KatalMetrics.Publisher;
    private initialized: boolean = false;
    private auxClickMetric = { name: 'isAuxClick', value: 'true' };

    public initialize(staticConfig: AxiomConfig.Configuration) {
        if (!this.initialized) {
            this.initialized = true;
            const domain = staticConfig.stage;
            const realm = staticConfig.realm;
            if (domain == 'dev') {
                this.metricsDriver = this.getDevMetricsDriver();
            } else {
                this.metricsDriver = new KatalMetricsDriverSushi.Builder()
                    .withDomainRealm(domain, realm)
                    .withErrorHandler(metricsConsoleErrorHandler)
                    .build();
            }

            if (this.metricsDriver) {
                const initialMetricsContext = new KatalMetrics.Context.Builder()
                    .withSite(SITE_NAME)
                    .withServiceName(APP_NAME)
                    .build();
                this.metricsPublisher = new KatalMetrics.Publisher(
                    this.metricsDriver,
                    metricsConsoleErrorHandler,
                    initialMetricsContext);
            } else {
                throw new Error('Failed to initialize metrics driver');
            }
        }
    }

    /**
     * Creates new KatalMetricsDriver for the console in dev
     * Optionally silenced when SILENCE_METRICS_LOG=true is added to the env
     * SILENCE_METRICS_LOG: defined in webpack using the env variable
     */
    private getDevMetricsDriver() {
        const devMetricsDriver = new KatalMetricsDriverConsoleLogJson();
        if (SILENCE_METRICS_LOG) { devMetricsDriver.publish = silentMetricsConsolePublisher; }
        return devMetricsDriver;
    }

    public getInitialMetricsPublisher(): KatalMetrics.Publisher {
        if (this.metricsPublisher) {
            return this.metricsPublisher;
        } else {
            throw new Error('Metric Publisher is not initialized yet');
        }
    }

    public publishMetric(componentName: string, actionName: string, monitorType: string, monitorkey: string, monitorValue: any, additionalMetrics?: Axiom.AdditionalMetric[]) {
        const actionMetricsPublisher = this.getChildActionPublisher(`${componentName}.${actionName}`);
        this.publishContextInformation(actionMetricsPublisher);
        if (additionalMetrics) {
            this.publishAdditionalMetrics(actionMetricsPublisher, additionalMetrics);
        }
        if (Reflect.has(actionMetricsPublisher, monitorType)) {
            let metricFn = Reflect.get(actionMetricsPublisher, monitorType).bind(actionMetricsPublisher);
            metricFn(monitorkey, monitorValue);
        } else {
            console.error([componentName, actionName, monitorType].join(',') + " monitorType is not supported in Katal Metrics Publisher");
        }
    }

    public publishButtonClick(componentName: string, buttonName: string, additionalMetrics?: Axiom.AdditionalMetric[]) {
        const actionMetricsPublisher = this.getChildActionPublisher(`${componentName}.${buttonName}`);
        this.publishContextInformation(actionMetricsPublisher);
        if (additionalMetrics) {
            this.publishAdditionalMetrics(actionMetricsPublisher, additionalMetrics);
        }
        actionMetricsPublisher.publishCounterMonitor('clicks', 1);
    }

    public publishLinkClick(componentName: string, buttonName: string, linkUrl: string, additionalMetrics?: Axiom.AdditionalMetric[], isAuxClick?: boolean) {
        const actionMetricsPublisher = this.getChildActionPublisher(`${componentName}.${buttonName}`);
        actionMetricsPublisher.publishString('linkUrl', linkUrl);
        this.publishContextInformation(actionMetricsPublisher);
        if (additionalMetrics) {
            this.publishAdditionalMetrics(actionMetricsPublisher, isAuxClick ?
                [...additionalMetrics, this.auxClickMetric] : additionalMetrics);
        } else {
            if (isAuxClick) {
                this.publishAdditionalMetrics(actionMetricsPublisher, [this.auxClickMetric]);
            }
        }
        actionMetricsPublisher.publishCounterMonitor('clicks', 1);
    }

    public publishPageLoad(componentName: string, url: string, additionalMetrics?: Axiom.AdditionalMetric[]) {
        const actionMetricsPublisher = this.getChildActionPublisher(`${componentName}.load`);
        actionMetricsPublisher.publishString('url', url.slice(0, 255));
        this.publishContextInformation(actionMetricsPublisher);
        if (additionalMetrics) {
            this.publishAdditionalMetrics(actionMetricsPublisher, additionalMetrics);
        }
        actionMetricsPublisher.publishCounterMonitor('loads', 1);
    }

    public publishMonitor(monitor: KatalMetricObject, methodName: string, additionalMetrics?: Axiom.AdditionalMetric[]) {
        const actionMetricsPublisher = this.getChildActionPublisher(`${methodName}`);
        this.publishContextInformation(actionMetricsPublisher);
        if (additionalMetrics) {
            this.publishAdditionalMetrics(actionMetricsPublisher, additionalMetrics);
        }
        actionMetricsPublisher.publish(monitor);
    }

    public publishInitializationMetric(monitor: KatalMetrics.Metric.Initialization, status: InitializationStatus, additionalMetrics?: Axiom.AdditionalMetric[]) {
        const metricsPublisher = AxiomMetricsDriver.getInitialMetricsPublisher().newChildActionPublisherForInitialization();
        monitor.setFailure(status !== InitializationStatus.Success);
        if (additionalMetrics) {
            this.publishAdditionalMetrics(metricsPublisher, additionalMetrics);
        }
        metricsPublisher.publish(monitor);
    }

    public publishAPICallStatus(apiName: string, status: MetricStatus) {
        if (!this.initialized) { return; } //This solve the case in which the authentication flow tries publish without initialization.
        const metricsPublisher = this.getChildActionPublisher(`API.${apiName}`);
        if (UserManager.isUserIdentityAvailable()) { //in certain cases like GetUserInformation is not available yet
            this.publishContextInformation(metricsPublisher);
        }
        metricsPublisher.publishCounterMonitor('callStatus', status.valueOf());
    }

    public publishAPIResponseTime(apiName: string, timer: KatalMetricTimerStopwatch | undefined) {
        if (!this.initialized) { return; } //This solve the case in which the authentication flow tries publish without initialization.
        const metricsPublisher = this.getChildActionPublisher(`API.${apiName}`);
        if (UserManager.isUserIdentityAvailable()) { //in certain cases like GetUserInformation is not available yet
            this.publishContextInformation(metricsPublisher);
        }
        if (timer) {
            metricsPublisher.publishTimerMonitor("responseTime", timer.value);
        }
    }

    public publishWeblabAssignment(login: string, weblabName: string, treatment: string) {
        const actionMetricsPublisher = this.getChildActionPublisher(`Weblab.${weblabName}`);
        if (UserManager.isUserIdentityAvailable()) { //in certain cases like GetUserInformation is not available yet
            this.publishContextInformation(actionMetricsPublisher);
        }
        actionMetricsPublisher.publishString('treatment', treatment);
        actionMetricsPublisher.publishCounterMonitor('weblabAssignment', 1);
    }

    public publishComponentLoadError(componentName: string, status: MetricStatus, additionalMetrics?: Axiom.AdditionalMetric[]) {
        if (!this.initialized) { return; } // This solves the case in which the authentication flow tries publish without initialization.
        const metricsPublisher = this.getChildActionPublisher(`Component.${componentName}`);
        if (additionalMetrics) {
            this.publishAdditionalMetrics(metricsPublisher, additionalMetrics);
        }
        if (UserManager.isUserIdentityAvailable()) { // in certain cases like GetUserInformation is not available yet
            this.publishContextInformation(metricsPublisher);
        }
        metricsPublisher.publishCounterMonitor("loadError", status.valueOf());
    }

    private publishContextInformation(publisher: KatalMetrics.Publisher) {
        publisher.publishString('user', UserManager.loggedUserIdentity!.login);
        publisher.publishString('url', window.location.href?.slice(0, 255) || 'url not available');
    }

    private publishAdditionalMetrics(publisher: KatalMetrics.Publisher, additionalMetrics: Axiom.AdditionalMetric[]) {
        for (let metric of additionalMetrics) {
            publisher.publishString(metric.name, metric.value);
        }
    }

    private getChildActionPublisher(methodName: string) {
        if (this.metricsPublisher) {
            return this.metricsPublisher.newChildActionPublisherForMethod(methodName);
        }
        throw new Error('Metric Publisher is not initialized yet');
    }

}

export const AxiomMetricsDriver = new AxiomMetrics();