import React from 'react';
import { hot } from 'react-hot-loader';
import * as KatalMetrics from '@amzn/katal-metrics';
import { i18nWhenReady } from '../i18n';
import { CerebrumService } from "../service/CerebrumCoralService";
import { KatSpinner } from "@amzn/katal-react";
import WFMAxiomWebApp from "src/components/WFMAxiomWebApp";
import { ConfigManager } from "src/config/ConfigManager";
import { UserManager } from "src/user/UserIdentityManager";
import { AxiomMetricsDriver, InitializationStatus } from "src/metrics/AxiomMetricsDriver";
import { ErrorBoundComponent, ErrorBoundState } from "src/components/error/ErrorBoundComponent";
import { AmplifyAuthenticationService } from "src/service/AmplifyAuthenticationService";

interface AppInitState extends ErrorBoundState {
    userIdentity: WFM.GetUserInformation | null;
    finishedAuth: boolean;
}

/**
 * Simple class to manage the application initialization metric.
 *
 * Note this is meant as a demonstration of how to use the initialization metric, and
 * is almost certainly inadequate for a more complex application.
 *
 * It waits for itself to be mounted (if it is the outermost component that means all of
 * its children are rendered and mounted by React, though are not necessarily fully rendered
 * in the browser), then waits for the translations to be ready.
 *
 * In your actual application, you are likely not fully initialized until you have completed
 * your initial REST calls.  You will need to either integrate that logic here, or else move
 * the initialization metric elsewhere.
 *
 * For applications using a state manager (e.g. Redux) it may make more sense to determine if
 * your application is initialized from that layer instead.
 */
export class AppInitWrapper extends ErrorBoundComponent<{}, AppInitState> {
    private initializationMetric = new KatalMetrics.Metric.Initialization().withMonitor();
    private isInitialized = false;

    constructor(props: any) {
        super(props);
        this.state = {
            userIdentity: null,
            hasError: false,
            errorMessage: "",
            finishedAuth: false
        };
    }

    componentDidMount() {
        ConfigManager.initialize()
            .then(() => {
                this.afterSettingsLoaded();
            });
    }

    static getDerivedStateFromError(_error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    afterSettingsLoaded() {
        AxiomMetricsDriver.initialize(ConfigManager.getStaticConfig());
        i18nWhenReady((err) => {
            this.logInitializationMetric(err ? InitializationStatus.Failure : InitializationStatus.Success);
        });

        const authenticateWithCognito = (userIdentity: WFM.GetUserInformation) => {
            // userIdentity is used to report metrics
            AmplifyAuthenticationService.authenticate(
                userIdentity,
                () => this.setState({ finishedAuth: true })
            ).catch(async (error) => {
                this.handleError(error);
            })
        }

        authenticateWithCognito(CerebrumService.emptyUserInformation);

        CerebrumService.getUserInformation()
            .then(userIdentity => {
                UserManager.setUserIdentity(userIdentity)
                    .then(() => {
                        this.setState({ userIdentity: UserManager.getUserIdentity() });

                        // Enables user count metrics for Spectrometer
                        const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
                            detail: { customerId: userIdentity.login },
                        });
                        document.dispatchEvent(authenticatedUserEvent);
                    })
            }).catch(async (error) => {
                this.handleError(error);
            }
        );
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.logInitializationMetric(InitializationStatus.Failure);
        this.handleError(error);
    }

    render() {
        let content = super.renderError();
        if (!content) {
            if (this.state.finishedAuth && this.state.userIdentity) {
                content = <WFMAxiomWebApp userIdentity={this.state.userIdentity!}/>;
            } else {
                content = <KatSpinner size="large"/>;
            }
        }
        return (
            content
        );
    }

    // Helper method to publish the initialization metric
    private logInitializationMetric(status: InitializationStatus) {
        if (!this.isInitialized && this.initializationMetric) {
            this.isInitialized = true;
            AxiomMetricsDriver.publishInitializationMetric(this.initializationMetric, status);
        }
    }
}

// Hot reload any react changes when running the dev server
export default hot(module)(AppInitWrapper);
