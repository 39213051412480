import React, { useEffect, useState } from 'react';
import { KatButton, KatButtonGroup } from '@amzn/katal-react';
import { publishAuditSelectorMetric } from './selectorMetrics';
import { ComponentID } from "src/components/authorization/ComponentRightsMapping";
import { isComponentAccessAllowed } from "src/components/authorization/UserRestrictedComponent";
import { UserManager } from "src/user/UserIdentityManager";
import { Treatment, Weblab } from "src/user/Weblab";


interface AuditTypeSelector {
    disabled?: boolean;
    selectedAuditType: Cerebrum.Audits.AuditType;
    onChangeHandler: (auditType: Cerebrum.Audits.AuditType) => void;
    auditor: string;
}

interface AuditTypeButtonProps {
    label: string;
    value: Cerebrum.Audits.AuditType;
    hasAccess: () => boolean;
}



export function AuditTypeSelector(props: AuditTypeSelector) {

    const { selectedAuditType, onChangeHandler, auditor } = props;

    /**
     * Access to the audit type selector options are controlled by the following rules:
     */
    const hasFalconAccess = (): boolean => {
        const isSeeingOwnAudits = UserManager.getUserIdentity().login === auditor;

        // Supervisors have the ability to see other people's falcon introspection audits
        return (isSeeingOwnAudits || isComponentAccessAllowed(ComponentID.SUPERVISOR_REVIEWS_ID));
    };

    const hasTitusAccess = (): boolean => {
        // Only supervisors can see TITUS.
        return isComponentAccessAllowed(ComponentID.SUPERVISOR_REVIEWS_ID);
    };

    const hasCustomerObsessionAccess = (): boolean => {
        const isSeeingOwnAudits = UserManager.getUserIdentity().login === auditor;
        return (isSeeingOwnAudits || isComponentAccessAllowed(ComponentID.SUPERVISOR_REVIEWS_ID));
    };

    const auditTypes = [
        {
            label: "DCP",
            value: "customerObsession",
            // TODO: Remove this once we correctly onboard a Falcon right. Switch back to 'Audit' Rights
            hasAccess: hasCustomerObsessionAccess
        },
        {
            label: "Falcon",
            value: "falconIntrospection",
            hasAccess: hasFalconAccess
        }
    ] as AuditTypeButtonProps[];

    const [availableAuditTypes, setAvailableAuditTypes] = useState<AuditTypeButtonProps[]>([]);


    useEffect(() => {
        const availableTypes = auditTypes.filter(type => {
            return type.hasAccess ? type.hasAccess() : true;
        });
        setAvailableAuditTypes(availableTypes);
    }, [props]);

    useEffect(() => {
        /** Default the selected audit type to the first one in the list if the selected type is not available */
        if (!selectedAuditType || !availableAuditTypes.find(type => type.value === selectedAuditType)) {
            onChangeHandler(availableAuditTypes[0]?.value || '');
        }
    }, [availableAuditTypes]);


    const onchange = (event: KatButtonGroup.ChangeEvent) => {
        const selectedAuditTypeValue = event.detail.value;
        publishAuditSelectorMetric({
            selectorName: 'typeSelector',
            selectorValue: selectedAuditTypeValue,
            selectedAuditType: selectedAuditTypeValue
        });
        onChangeHandler(selectedAuditTypeValue);
    };

    return (
        <KatButtonGroup value={selectedAuditType} onChange={onchange} >
            {availableAuditTypes.map(auditType =>
                <KatButton
                    key={auditType.value}
                    label={auditType.label}
                    value={auditType.value}
                    variant="primary"
                />
            )}
        </KatButtonGroup>
    );
}
